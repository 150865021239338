.product-item {
    .product-item-info {
        .product-item-name {
            a.product-item-link {
                font-size: 0.9rem;
            }
        }
    }
    .product-item > a img {
        right: 285px;
        left: initial;
    }
}

.contentmanager-contenttype-homepage .splide__list > .splide__slide .product-item {
    @include product-item-image-zoom;
}
