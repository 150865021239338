.catalog-category-view,
.catalogsearch-result-index {
    .columns .column.main {
        .amscroll-bottom {
            font-size: 0.8rem;
        }

        .amscroll-top {
            font-size: 0.8rem;
        }

        .select2-container {
            margin-top: 0.35rem !important;
        }
    }
}

.ambrand-index-index {
    .select2-container {
        margin-top: 0.3rem !important;
    }
}
