.page-footer {
    .footer-part {
        .footer-content {
            width: $container-hd;

            .footer-options-links {
                height: fit-content;
            }
        }
    }
}
