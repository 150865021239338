header.page-header {
    .navigation-container {
        .navigation {
            ul[role='menubar'] {
                li.level0 {
                    & a {
                        font-size: 0.8rem;
                    }
                    ul.level0 {
                        padding: 34px calc((100% - #{$container-hd}) / 2) 0;
                    }
                }
            }
        }
    }
    .header.content {
        .link-search {
            .block-search {
                .block-content {
                    .control {
                        input {
                            width: 360px;
                        }
                    }
                }
            }
        }
    }

    .top-line-banner {
        margin: 0px;

        .top-line-banner-item-wrapper {
            max-width: $container-hd;
        }
    }
}
