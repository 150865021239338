body.account {
    .page-main {
        .page-title-wrapper {
            font-size: 1rem;
        }
        .columns .column.main {
            .block-title {
                font-size: 1rem;
                strong {
                    font-size: 1.4rem;
                }
            }
        }
        .sidebar-main {
            width: 25%;
            .account-nav,
            #amasty-affiliate-collapsible {
                .item {
                    a {
                        font-size: 0.8235rem;
                    }

                    &.current a,
                    &.current strong {
                        font-size: 0.8235rem;
                    }
                }
            }
        }
    }
}
