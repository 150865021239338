.checkout-cart-index {
    .image-ribbon {
        .ribbon-inner {
            padding: 2px 0.5rem;
        }
        .ribbon-dlc {
            padding: 2px 0.5rem 2px 0.7rem;
        }
    }
}
