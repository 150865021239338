body.contentmanager-contenttype-homepage {
    .homepage {
        .slideshow {
        }
        .bestsellers {
            .bestsellers-row {
                margin-bottom: 1.45rem;
            }
        }
        .block-home {
            margin-top: 21px;
            // height / bloc = hauteur du parent - ( sommes des margin + margin bottom de la dernière carte de bestsale) à diviser par 4 blocs
            height: calc((100% - (21px * 3 + 3rem + 1.45rem)) / 4);
        }
    }
}
