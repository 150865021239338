.coming-soon {
    height: 100%;

    &-info {

        padding: 16px;

        &-countdown {
            .cm-countdown {
                gap: 0;
                justify-content: space-between;
            }

            .cm-countdown-progress {
                margin-top: 16px;
            }
        }

        &-title {
            margin-top: 12px;
            font-size: 18px;
            line-height: 24px;
        }

        &-description {
            margin-bottom: 16px;
        }

        &-price {
            margin: 12px 0;
        }

        &-actions {
            .action.primary {
                padding: 16px 28px;
            }
        }
    }

    .slider-footer {
        width: calc(100 - 32px);
        padding: 16px 0;
        margin: 0 16px;
    }

    &-image {
        min-height: 214px;
    }
}
